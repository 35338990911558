import React from 'react';
import '../assets/styles/components/Brand.css';

const Brand = () => (
  <section id="brand_info">
    <h2>Marca</h2>
    <div className="service">
      <h3>Servicio 1</h3>
      <p>Descripción del servicio 1.</p>
    </div>
    <div className="service">
      <h3>Servicio 2</h3>
      <p>Descripción del servicio 2.</p>
    </div>
  </section>
);

export default Brand;
