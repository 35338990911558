import React from 'react';

const Products = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Productos</h1>
    <p>Aquí se mostrarán los productos.</p>
  </div>
);

export default Products;
