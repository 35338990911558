import React from 'react';
import CarrouselContent from '../components/CarrouselContent';
import Brand from '../components/Brand';

const Home = () => (
  <>
    <CarrouselContent />
    <Brand />
  </>
);

export default Home;
