import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../assets/styles/components/CarrouselContent.css';
import image1 from '../assets/images/carrousel/image1.jpg';
import image2 from '../assets/images/carrousel/image2.jpg';
import image3 from '../assets/images/carrousel/image3.jpg';
import image4 from '../assets/images/carrousel/image4.jpg';

const CarrouselContent = () => (
  <section id="hero">
    <Carousel
      className="carousel"
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay>
      <div>
        <img src={image1} alt="Slide 1" />
        <div className="slide-content">
          <h1 className="title">Ultra Construction</h1>
          <p className="subtitle">With over 20 years of contracting and construction experience, we'll bring our wealth of knowledge and insight to provide the most complete industrial solutions for your next development.</p>
          <div className="button-container">
            <a className="button primary" href="#services">Our Marca</a>
            <a className="button" href="#quote">Get a Quote</a>
          </div>
        </div>
      </div>
      <div>
        <img src={image2} alt="Slide 2" />
        <div className="slide-content">
          <h1 className="title">Another Title 2</h1>
          <p className="subtitle">Another subtitle goes here for the second slide.</p>
          <div className="button-container">
            <a className="button primary" href="#services">Our Marca</a>
            <a className="button" href="#quote">Get a Quote</a>
          </div>
        </div>
      </div>
      <div>
        <img src={image3} alt="Slide 3" />
        <div className="slide-content">
          <h1 className="title">Another Title 3</h1>
          <p className="subtitle">Another subtitle goes here for the second slide.</p>
          <div className="button-container">
            <a className="button primary" href="#services">Our Marca</a>
            <a className="button" href="#quote">Get a Quote</a>
          </div>
        </div>
      </div>
      <div>
        <img src={image4} alt="Slide 4" />
        <div className="slide-content">
          <h1 className="title">Another Title 4</h1>
          <p className="subtitle">Another subtitle goes here for the second slide.</p>
          <div className="button-container">
            <a className="button primary" href="#services">Our Marca</a>
            <a className="button" href="#quote">Get a Quote</a>
          </div>
        </div>
      </div>
    </Carousel>
  </section>
);

export default CarrouselContent;
