import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import '../assets/styles/components/Header.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
      document.documentElement.style.setProperty('--header-bg-color', 'rgba(0, 0, 0, 0.5)');
      document.documentElement.style.setProperty('--link-color', 'white');
    } else {
      setScrolled(false);
      document.documentElement.style.setProperty('--header-bg-color', 'rgba(255, 255, 255, 0.0)');
      document.documentElement.style.setProperty('--link-color', 'white');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="header-wrapper">
      <header className="header-container">
        <Logo />
        <nav className="nav-links">
          <Link to="/">Inicio</Link>
          <a href="#brand_info">Marca</a>
          <Link to="/products">Productos</Link>
          <Link to="/contact">Contacto</Link>
        </nav>
      </header>
    </div>
  );
};

export default Header;
