import React from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import CopyBrandText from './CopyBrandText';
import '../assets/styles/components/Footer.css';

const Footer = () => (
  <footer className="footer-wrapper">
    <div className="footer-container">
      <p className="slogan-text">La ciencia del algoritmo</p>
      <div className="social-links">
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>
      <p className="footer-text">© 2024 <CopyBrandText /></p>
    </div>
  </footer>
);

export default Footer;
