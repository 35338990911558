import React from 'react';
import '../assets/styles/components/Logo.css';

const Logo = () => (
  <div className="logo-container">
    <span className="logo-text">algorística</span>
    <span className="cursor" />
  </div>
);

export default Logo;
