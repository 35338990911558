import React from 'react';
import '../assets/styles/components/CopyBrandText.css';

const CopyBrandText = () => (
  <>
    <span className="copy-brand-text">algorística</span>
  </>
);

export default CopyBrandText;
