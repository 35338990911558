import React from 'react';
import '../assets/styles/components/Contact.css';

const Contact = () => (
  <div className="contact-wrapper">
    <div className="spacer" />
    <div className="contact-form-wrapper">
      <h1>Contáctanos</h1>
      <form className="contact-form">
        <input type="text" placeholder="Your Name" />
        <input type="email" placeholder="Your Email" />
        <input type="text" placeholder="Subject" />
        <textarea placeholder="Message"></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
    <div className="spacer" />
  </div>
);

export default Contact;
